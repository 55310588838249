<script>
import { customerService } from "../../../helpers/backend/customer.service";
import { vehicleTypeService } from "../../../helpers/backend/vehicle_type.service";
import VuePhoneNumberInput from "vue-phone-number-input";
import Datepicker from "vuejs-datepicker";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  components: {
    VuePhoneNumberInput,
    Datepicker,
  },
  data() {
    return {
      form: {
        id: null,
        name: "",
        vehicle_number: "",
        phone_number: "",
        vehicle_type_id: null,
        vehicle_year: null,
      },
      vehicle_brand: null,
      busy: false,
      media_url: "",
      vehicle_brand_options: [],
      vehicle_type_options: [],
      show: true,
      showDismissibleAlert: false,
      error: "",
      disabledDates: {
        ranges: [
          {
            // Disable dates in given ranges (exclusive).
            from: new Date(1970, 1, 1),
            to: new Date(1990, 1, 1),
          },
          {
            from: new Date().setFullYear(new Date().getFullYear()+1),
            to: new Date(2100, 2, 25),
          },
        ],
      },
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    isUpdateState() {
      return this.form.id != null;
    },
    computed_vehicle_type_options() {
      return this.vehicle_type_options[this.vehicle_brand];
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      //this.form.media = this.$refs.file.files[0]
      this.busy = true;
      customerService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset();
            this.$emit("refresh");
            this.showDismissibleAlert = true;
            this.error = "";
          }
          this.busy = false;
        },
        (error) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to Insert/Update Customer. Error : " + error;
          this.busy = false;
        }
      );
    },
    reset() {
      // Reset our form values
      this.form.id = null;
      this.form.name = "";
      this.form.vehicle_number = "";
      this.form.phone_number = "";
      this.form.vehicle_type_id = null;
      this.form.vehicle_year = null;
      this.vehicle_brand = null;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.showDismissibleAlert = false;
      this.error = "";
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onReset(event) {
      event.preventDefault();
      this.reset();
    },
    initUpdate(id) {
      this.reset();
      this.$refs.name.$el.focus();
      this.busy = true;
      customerService.getById(id).then(
        (data) => {
          if (data.customer != null) {
            this.form.id = data.customer.ID;
            this.form.name = data.customer.name;
            this.form.vehicle_number = data.customer.vehicle_number;
            this.form.phone_number = data.customer.phone_number;
            this.form.vehicle_type_id = data.customer.vehicle_type_id;
          } else {
            this.showDismissibleAlert = true;
            this.error =
              "Failed to get advertisement to update. Error : Advertisement not found";
          }
          this.busy = false;
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to get advertisement to update. Error : " + err;
          this.busy = false;
        }
      );
    },
    fetchData() {
      vehicleTypeService.getAll().then(
        (data) => {
          this.vehicle_brand_options = [];
          for (let index = 0; index < data.vehicle_types.length; index++) {
            const element = data.vehicle_types[index];
            if (!this.vehicle_brand_options.includes(element.vehicle_brand)) {
              this.vehicle_brand_options.push(element.vehicle_brand);
              this.vehicle_type_options[element.vehicle_brand] = [
                { text: "Select One", value: null },
              ];
            }
            this.vehicle_type_options[element.vehicle_brand].push({
              text: element.vehicle_series,
              value: element.ID,
            });
          }
          //alert(typeof(this.machine_types))
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to get vehicle type. Error : " + err;
        }
      );
    },
  },
};
</script>


<template>
  <div>
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Customer Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Enter Customer Name"
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Vehicle Number:"
                label-for="input2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.vehicle_number"
                  type="text"
                  placeholder="Enter Vehicle Number"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Phone number:"
                label-for="input-3"
              >
                <VuePhoneNumberInput
                  id="input-3"
                  :only-countries="['ID']"
                  :default-country-code="'ID'"
                  v-model="form.phone_number"
                />
              </b-form-group>

              <b-form-group
                id="input-group-5"
                label="Vehicle Type:"
                label-for="input-5"
              >
                <b-form-select
                  id="input-5"
                  v-model="vehicle_brand"
                  :options="vehicle_brand_options"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-5"
                label="Vehicle Type:"
                label-for="input-5"
              >
                <b-form-select
                  id="input-5"
                  v-model="form.vehicle_type_id"
                  :options="computed_vehicle_type_options"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-6"
                label="Vehicle Year:"
                label-for="input-6"
              >
                <Datepicker
                  v-model="form.vehicle_year"
                  format="yyyy"
                  minimum-view="year"
                  name="datepicker"
                  ref="date_picker"
                  id="input-6"
                  input-class="input-class"
                  required
                  v-show="show"
                  :disabled-dates="disabledDates"
                ></Datepicker>
              </b-form-group>

              <b-button type="submit" variant="primary">{{
                isUpdateState ? "Update" : "Submit"
              }}</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>



